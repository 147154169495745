<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userName | capitalize }}
        </p>
        <span class="user-status">{{ role | camelCase }}</span>
      </div>
      <b-avatar
        size="40"
        :src="img"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="0"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!-- <b-dropdown-divider /> -->
    <b-dropdown-item v-if="this.$store.getters.currentUser && this.$store.getters.currentUser.role != 'super_admin' " link-class="d-flex align-items-center" :to="{ path: path }">
      <feather-icon size="16" icon="ApertureIcon" class="mr-50"/>
      <span>Profile</span>
    </b-dropdown-item>
    
    <b-dropdown-item v-if="this.$store.getters.currentUser && this.$store.getters.currentUser.role != 'super_admin' " link-class="d-flex align-items-center" :to="{ path: path_cp }">
      <feather-icon size="16" icon="LockIcon" class="mr-50"/>
      <span>Change Password</span>
    </b-dropdown-item>
    
    <b-dropdown-item v-if="this.$store.getters.currentUser && this.$store.getters.currentUser.role == 'super_admin' " link-class="d-flex align-items-center" :to="{ path: path_sa }">
      <feather-icon size="16" icon="LockIcon" class="mr-50"/>
      <span>Change Password</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
      <span>Logout</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import { POST_API,LOGOUT,SET_PROFILE_DATA } from "../../../../../store/actions.type"
import store from "../../../../../store"
import Bus from "../../../../../event-bus";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      img:'',
      userName: this.$store.getters.currentUser.full_name,
      role: this.$store.getters.currentUser.role,
      path: '/client/view-profile',
      path_cp: '/client/change-password',
      path_sa: '/admin/change-password'
    }
  },
  methods: {
    logout() {
      
      var role = store.getters.currentUser.role;
      var id = store.getters.currentUser._id;

      if (role == 'super_admin') {
        var routeName = 'admin-login'
      }else{
        var routeName = 'login'
      }

      this.$store.dispatch(LOGOUT, {
          data: {
          },
          api: '/api/logout?id='+id
      })
      .then(() => {
          if (!this.$store.getters.containsErrors) {
              this.$router.push({ name: routeName })
          }
      });
    },

    CompanyInfo() {

      this.$store.dispatch(POST_API, {
          data: {
            id: this.$store.getters.currentUser.organization
          },
          api: '/api/client-detail'
      })
      .then(() => {
          var data = this.$store.getters.getResults.data;
          this.img = data.company_logo_thumbnail;
          //console.log(data,this.img)
      });
    },

    updateProfile() {

      this.$store.dispatch(SET_PROFILE_DATA, {
          data: {
            id: this.$store.getters.currentUser._id
          },
          api: '/api/reset-profile'
      })
      .then(() => {
          var data = this.$store.getters.getResults;
          //console.log(this.$store.getters.currentUser);
          this.userName = this.$store.getters.currentUser.full_name;
          this.role = this.$store.getters.currentUser.role;
          if (this.$store.getters.currentUser.role != 'super_admin') {
              this.CompanyInfo();
          }else {
              this.img = this.$store.getters.currentUser.profile_thumbnail;
          }
          
          //console.log(data,this.img)
      });
    },

    /*StaffInfo() {

      this.$store.dispatch(POST_API, {
          data: {
            id: this.$store.getters.currentUser._id
          },
          api: '/api/edit-staff'
      })
      .then(() => {
          var data = this.$store.getters.getResults.data;
          this.img = data.profile_thumbnail;
          //console.log(data,this.img)
      });
    },*/
  },
  mounted(){
    if (this.$store.getters.currentUser.role != 'super_admin') {
        this.CompanyInfo();
    }else {
        this.img = this.$store.getters.currentUser.profile_thumbnail;
        //this.updateProfile();
    }

    /*if(this.role == 'site_client' || this.role == 'site_manager'){
      this.path = '/site/view-profile';
    } else {
    }*/
    this.path = '/client/view-profile';

    Bus.$on('update-profile', () => {

        this.updateProfile();
        //console.log('aya kuch')
    })

    Bus.$on('checkuser', () => {
        if (this.$store.getters.currentUser != null && this.$store.getters.currentUser.full_name != this.userName) {
          
          window.location.reload(true);

        }
    })

  }
}
</script>
